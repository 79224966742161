<div *ngIf='isInitialised' class='padding jumbotron'>
  <form [formGroup]='itemForm'>
    <div class='row align-items-center'>
      <div class='col-9'>
        <label class='bold'>Company Name</label>
        <input type='text' class='form-control' formControlName='nameControl' [attr.disabled]='true' />
      </div>
      <div class='col-3'>
        <span class='company-logo'>
          <img src='{{logo}}' alt='company-logo' />
        </span>
      </div>
      <div class='col-12'>
        <hr>
      </div>
    </div>
    <div class='row'>
      <div class='col-6 mt-3'>
        <label class='bold'>AltiusLife</label>
        <app-input-copy [form]='itemForm' [prefixCopy]='"https://"' [forceLowercase]='true' [formControlName]="'domainControl'" [isDisabled]='true'></app-input-copy>
      </div>
      <div class='col-6 mt-3'>
        <label><span class='bold'>Registration Code</span> (Website and Mobile App)</label>
        <app-input-copy [form]='itemForm' [formControlName]="'registrationCodeControl'" [isDisabled]='true'></app-input-copy>
      </div>
      <div class='col-12'>
        <hr>
      </div>
    </div>
    <div *ngIf='hasInsights' class='row'>
      <div class='col-12'>
        <label class='bold'>AltiusInsights</label>
        <app-input-copy [form]='itemForm' [prefixCopy]='"https://"' [forceLowercase]='true' [formControlName]="'insightsDomainControl'" [isDisabled]='true'></app-input-copy>
      </div>
      <div class='col-12'>
        <hr>
      </div>
    </div>
    <div class='row'>
      <div class='col-12'>
        <label class='bold'>Booking Link</label>
        <app-input-copy [form]='itemForm' [formControlName]="'bookingLinkControl'" [isDisabled]='true'></app-input-copy>
      </div>
      <div class='col-8 mt-3'>
        <label class='bold'>Posters and Brochures​</label>
        <p>Promote your EAP program and AltiusLife with a wide range of assets to share digitally or print locally</p>
        <div class='row brochure-img'>
          <a [routerLink]='posterBrochureUrl'>
            <img src='assets/img/posters-brochures/postersBrochuresThumbnail.png' alt='booking-poster' />
          </a>
        </div>
      </div>
      <div class='col-7 mt-3'>
        <label class='bold'>Booking Site Integration</label>
        <P>{{leapIntegration ? 'YES' : 'NO'}}</P>
      </div>
      <div class='col-12'>
        <hr>
      </div>
    </div>
    <div class='row'>
      <div *ngIf='canShowEditButton()' class='col-4'>
        <button type='button' class='btn btn-primary bold w-100 mt-3' (click)='onEditClick()'>{{getEditButtonText()}}</button>
      </div>
      <div *ngIf='!canShowEditButton()' class='col-12'>
        <p>To edit information on this page, please contact us at <a href="mailto:info@altiuslife.com.au">info@altiuslife.com.au</a></p>
      </div>
    </div>
  </form>
</div>
