<img class="full-image mobile-heading" src="../../../assets/img/badges/badges.wide.png">
<div *ngIf="userService.isCompanyUsingUserlevels()" class="w-100 mt-3 text-center">
  <img class="plaque" src="assets/img/badges/{{userBadgeLevel | lowercase}}-plaque.png" />
  <div align="center">
    <table class="medals">
      <tr>
        <th colspan="3">
          Badges needed to level up
        </th>
      </tr>
      <tr>
        <td class="text-center">
          <img class="badgeSmall" src="assets/img/badges/badge_gold.svg" />
        </td>
        <td class="text-center">
          <img class="badgeSmall" src="assets/img/badges/badge_silver.svg" />
        </td>
        <td class="text-center">
          <img class="badgeSmall py-2" src="assets/img/badges/badge_bronze.svg" />
        </td>
      </tr>

      <tr>
        <td class="text-center" [ngClass]="{'achievedTextColor': userLevel.gold >= userLevel.goldNeeded}">
          {{userLevel.gold}} / {{userLevel.goldNeeded}}
          <td class="text-center" [ngClass]="{'achievedTextColor': userLevel.silver >= userLevel.silverNeeded}">
            {{userLevel.silver}} / {{userLevel.silverNeeded}}
          </td>
          <td class="text-center" [ngClass]="{'achievedTextColor': userLevel.bronze >= userLevel.bronzeNeeded}">
            {{userLevel.bronze}} / {{userLevel.bronzeNeeded}}
          </td>
      </tr>
    </table>
  </div>
</div>
<ngb-tabset class="col-12  mt-5">
  <ngb-tab title='Your badges' id="mine">
    <ng-template ngbTabContent>
      <div class="bg-tabset p-2">
        <h4 class="primaryColor mt-3 pl-2">
          <i class="fa fa-trophy mr-2" aria-hidden="true"></i> Achieved Badges
        </h4>
        <div class="row m-0">
          <div class="col-12 p-3 text-center card" *ngIf="!achievedBadges.length"> No badges achieved found.</div>
          <div class="col-12 col-sm-6 mt-3 d-flex" *ngFor="let b of achievedBadges">
            <div class="card w-100">
              <div class="row">
                <div class="col-3 p-3">
                  <div class="w-100" [ngSwitch]="b.badgeLevel">
                    <img class="badge-logo px-2" *ngSwitchCase="'bronze'" src="../../../assets/img/badges/badge_bronze.svg">
                    <img class="badge-logo px-2" *ngSwitchCase="'silver'" src="../../../assets/img/badges/badge_silver.svg">
                    <img class="badge-logo px-2" *ngSwitchCase="'gold'" src="../../../assets/img/badges/badge_gold.svg">
                  </div>
                </div>
                <div class="col-8 p-3">
                  <h6 class="pt-2 pr-1">{{b.name}}</h6>
                  <p class="pr-1 mb-0">{{b.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 class="primaryColor mt-3 pl-2 pt-3">
          <i class="fa fa-tasks mr-2" aria-hidden="true"></i> Badges in progress
        </h4>
        <div class="row m-0">
          <div class="col-12 p-3 text-center card" *ngIf="!inProgessBadges.length"> No badges in progress found.</div>
          <div class="col-12 col-sm-6 mt-3 d-flex" *ngFor="let b of inProgessBadges">
            <div class="card w-100">
              <div class="row m-0">
                <div class="col-3 p-2">
                  <div class="w-100">
                    <round-progress [current]="b.progress || 0" [max]="b.goal || 0" [semicircle]="false" [responsive]="true" [stroke]=30 [color]="getGrathColor(b.badgeLevel)">
                    </round-progress>
                    <div class="w-100 text-center ml-1 mt-1">
                      <span>{{getPercentage(b.goal, b.progress)}} %</span>
                    </div>
                  </div>
                </div>
                <div class="col-8 p-3">
                  <h6 class="pt-2 pr-1">{{b.name}}</h6>
                  <p class="pr-1 mb-0">{{b.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab title='All available badges' id='all'>
    <ng-template ngbTabContent>
      <div class="bg-tabset p-2">
        <div class="row">
          <div class="col-12 col-sm-6 mt-3 d-flex" *ngFor="let b of allBadges">
            <div class="card w-100">
              <div class="row">
                <div class="col-3 p-3">
                  <div class="w-100" [ngSwitch]="b.badgeLevel">
                    <img class="badge-logo px-2" [ngClass]="{'opacity-badge':!b.badgeEarned}" *ngSwitchCase="'bronze'" src="../../../assets/img/badges/badge_bronze.svg">
                    <img class="badge-logo px-2" *ngSwitchCase="'silver'" src="../../../assets/img/badges/badge_silver.svg">
                    <img class="badge-logo px-2" *ngSwitchCase="'gold'" src="../../../assets/img/badges/badge_gold.svg">
                  </div>
                </div>
                <div class="col-8 p-3">
                  <h6 class="pt-2 pr-1">{{b.name}}</h6>
                  <p class="pr-1 mb-0">{{b.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab *ngIf="userService.isCompanyUsingUserlevels()" title='How to level up' id='levelup'>
    <ng-template ngbTabContent>
      <div class="bg-tabset p-2">
        <h5 class="txtGray" text-center>Your {{userService.brand}} Level</h5>
        <p>The more you use the {{userService.brand}}
           App, the more badges you will earn. As you earn badges, you will level up and in return, get increased
          benefits like getting access to more vouchers.</p>
          <p>Badges are divided in three categories:
            <b>Bronze</b>,
            <b>Silver</b>, and
            <b>Gold</b>.</p>
          <p>They are obtained by viewing articles, videos and recipes, reporting on your own health metrics and completing
            LifeActions.
          </p>
        <b>How many badges each level requires:</b>
        <!-- </p> -->
        <table class="table-levels">
          <tr>
            <th class="mr-3 text-center" text-center></th>
            <th class="text-center">
              <img src="assets/img/badges/badge_gold.svg">
            </th>
            <th class="text-center">
              <img src="assets/img/badges/badge_silver.svg">
            </th>
            <th class="text-center">
              <img src="assets/img/badges/badge_bronze.svg">
            </th>
          </tr>
          <tr>
            <td class="mr-3 ">Apprentice</td>
            <td class="text-center">-</td>
            <td class="text-center">2</td>
            <td class="text-center">10</td>
          </tr>
          <tr>
            <td class="mr-3">Expert</td>
            <td class="text-center">-</td>
            <td class="text-center">10</td>
            <td class="text-center">20</td>
          </tr>
          <tr>
            <td class="mr-3">Master</td>
            <td class="text-center">15</td>
            <td class="text-center">15</td>
            <td class="text-center">20</td>
          </tr>
        </table>
      </div>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
