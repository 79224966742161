import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { UserService } from '../../../services/user-service';
import { ActivatedRoute } from '@angular/router';
import { IMarketingTemplate } from '../../_models/marketing-templates';
import { MarketingTemplateService } from '../../../services/marketing-template.service';
import { ICompany } from '../../_models/company';

@Component({
  selector: 'app-posters-brochures',
  templateUrl: './posters-brochures.component.html',
  styleUrls: ['./posters-brochures.component.scss'],
})
export class PosterBrochureComponent extends UnSubscribeComponent implements OnInit {
  @ViewChild('bookingPoster') bookingPoster: ElementRef;
  companyId: number;
  previewMode = false;
  isLoading: boolean;
  isCoordinator: boolean;
  templates: IMarketingTemplate[] = [];
  templateTypes: any[] = [];
  company: ICompany;

  marketingBrochureUrl: string;
  marketingPosterUrl: string;
  bookingPosterUrl: string;
  posterBrochureUrl: string;

  walletCardTemplateType: string;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private marketingTemplateService: MarketingTemplateService,
  ) {
    super();
  }

  ngOnInit() {
    this.isCoordinator = this.userService.isCoordinatorUser();
    this.activatedRoute.params.subscribe((route) => {
      this.companyId = route.id ? +route.id : this.userService.company.id;
      this.getData();
      const urlParam = route.id ? '/' + route.id : '';
      this.marketingBrochureUrl = `/cms/marketing-brochure${urlParam}`;
      this.marketingPosterUrl = `/cms/marketing-poster${urlParam}`;
      this.bookingPosterUrl = `/cms/booking-poster${urlParam}`;
      this.posterBrochureUrl = `/cms/posters-brochures`;
      this.walletCardTemplateType = this.marketingTemplateService.TEMPLATE_TYPES.card.name;
    });
  }

  private getTemplateByTypes(type: string) {
    return this.templates.filter(template => template.type === type);
  }

  private getTemplateTypeDisplayName(type: string) {
    const templateTypeData = this.marketingTemplateService.TEMPLATE_TYPES[type];
    if (!templateTypeData) {
      return type.toUpperCase() || type;
    }
    return templateTypeData.displayName || type;
  }

  private categorizeTemplates() {
    const templateTypes = [];
    this.templates.reduce((acc, crr) => {
      const templateType = crr.type;
      if (!acc[templateType]) {
        acc[templateType] = [];
        templateTypes.push({
          name: templateType,
          displayName: this.getTemplateTypeDisplayName(templateType),
        });
      }
      acc[templateType].push(crr);
      return acc;
    }, {});
    this.templateTypes = templateTypes.sort((tt1, tt2) => tt1.displayName > tt2.displayName ? 1 : -1);
  }

  private getData() {
    this.isLoading = true;
    this.marketingTemplateService.getAllTemplates(this.companyId)
      .subscribe((res) => {
        this.isLoading = false;
        this.templates = (res.marketingTemplates || []).sort((a, b) => a.id > b.id ? 1 : -1);
        this.company = res.company;
        this.categorizeTemplates();
      });
  }

  handleViewDetailTemplate(id) {
    return this.isCoordinator ? `/cms/marketing-template-detail/${id}` : `/cms/marketing-template-detail/${id}/${this.companyId}`;
  }
}
