import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICompany } from '../app/_models/company';

@Injectable({
  providedIn: 'root',
})
export class MarketingTemplateService {
  private baseUrl = '/cms/marketing-template';
  TEMPLATE_TYPES = {
    brochure: {
      name: 'brochure',
      displayName: 'BROCHURES'
    },
    poster: {
      name: 'Poster',
      displayName: 'POSTERS'
    },
    card: {
      name: 'card',
      displayName: 'WALLET CARD',
    }
  };

  constructor(
    private http: HttpClient,
  ) {
  }

  getAllTemplates(companyId: number) {
    const url = `${this.baseUrl}/all?companyId=${companyId}`;
    return this.http.get<any>(url);
  }

  getTemplateData(templateId: number, companyId: number) {
    const url = `${this.baseUrl}/${templateId}?companyId=${companyId}`;
    return this.http.get<ICompany>(url);
  }
}
