<div class='wrapper' *ngIf='isInsightsDomain()'>
  <app-navbar-insights *ngIf='isLoggedIn' class='fixed-top'></app-navbar-insights>
  <div class='container-fluid insights-container'>
    <div class='row h-100'>
      <div class='col-12 col-md-12 col-lg-12 p-0'>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<div class='wrapper' *ngIf='!isInsightsDomain()'>
  <app-navbar *ngIf='canShowAppNavbar()' class='fixed-top'></app-navbar>

  <div [class]="canShowAppNavbar()? 'router-outlet' : 'router-outlet m-0'">

    <div *ngIf='!isQRPage && (!isLoggedIn || isUserProfileNotCreated())' class='container-fluid'>
      <div class='row'>
        <div class='col-12 col-md-12 col-lg-12 p-0'>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

    <div *ngIf='isQRPage'>
      <div class='container-fluid qr-page-container'>
        <router-outlet></router-outlet>
      </div>
    </div>

    <div *ngIf='isCMS' class='container-fluid content-margin-cms'>
      <div class='row m-0'>
        <div class='col-12 col-sm-3 col-lg-2 m-0 p-0 h-100 collapse show mt-3' id='collapseExample'>
          <nav class='nav flex-column sticky-top'>
            <li class='nav-item'>
              <span class='dashboard backPrimary'><i class='fa fa-list-alt mr-1' aria-hidden='true'></i>Dashboard</span>
            </li>
            <li class='nav-item' *ngFor='let group of cmsRoutes'>
              <div *ngIf='group.groupId==="item-0"'>
                <ul class='nav flex-column' *ngFor='let route of group.routes'>
                  <li class='nav-item'>
                    <a class='nav-link' [routerLink]='route.routerLink' routerLinkActive='menuActive'>{{route.routerName}}</a>
                  </li>
                </ul>
              </div>
              <a *ngIf='group.groupId!=="item-0"' class='nav-link dropdown-toggle flex-nowrap bottomlinegray' data-toggle='collapse' [href]='group.groupHref'>{{group.groupTitle}}</a>
              <div *ngIf='group.groupId!=="item-0"' [id]='group.groupId' class='collapse show' aria-expanded='false'>
                <ul class='nav flex-column' *ngFor='let route of group.routes'>
                  <li class='nav-item'>
                    <a class='nav-link pl-5' [routerLink]='route.routerLink' routerLinkActive='menuActive'>{{route.routerName}}</a>
                  </li>
                </ul>
              </div>
            </li>
          </nav>
        </div>
        <div class='col-12 col-sm-9 col-lg-10 p-0'>
          <div class='row m-0'>
            <div class='col-12 pr-0'>
              <h1 class='sticky-top mt-2'>
                <button class='btn sticky-top' data-toggle='collapse' href='#collapseExample' role='button'>
                  <i class='fa fa-bars' aria-hidden='true'></i>
                </button>
                {{cmsTitle}}</h1>
            </div>
            <div class='col-12 pr-0'>
              <hr />
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf='isSettings' class='container-fluid contentMarginCMS'>
      <div class='row m-0'>
        <div class='col-12 col-sm-3 col-lg-2 m-0 p-0 h-100 collapse show mt-3' id='collapseExample'>
          <nav class='nav flex-column sticky-top'>
            <li class='nav-item'>
              <a class='nav-link dashboard backPrimary' [routerLink]="['/settings']">
                <i class='fa fa-list-alt mr-1' aria-hidden='true'></i>Settings</a>
            </li>
            <li class='nav-item'>
              <a class='nav-link dropdown-toggle flex-nowrap bottomlinegray' data-toggle='collapse' href='#item-1'>My
                Account</a>
              <div id='item-1' class='collapse show' aria-expanded='false'>
                <ul class='nav flex-column'>
                  <li class='nav-item'>
                    <a class='nav-link pl-5 ' (click)='openProfileModal()'>Profile</a>
                  </li>
                </ul>
                <ul class='nav flex-column'>
                  <li class='nav-item'>
                    <a *ngIf='canShowSubscription()' class='nav-link pl-5 '
                       [routerLink]="['/settings/subscription']">Subscription</a>
                  </li>
                </ul>
              </div>
            </li>
          </nav>
        </div>
        <div class='col-12 col-sm-9 col-lg-10 p-0'>
          <div class='row m-0'>
            <div class='col-12 pr-0'>
              <h1 class='sticky-top mt-2'>
                {{settingsTitle}}</h1>
            </div>
            <div *ngIf='isSettings' class='col-12 pr-0'>
              <hr />
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf='canShowBreadcrumbs()'>
      <div *ngIf='canShowCarousel()' class='carousel-margin'>
        <app-carousel></app-carousel>
      </div>
      <div [class]="isHome ? 'bread-container-home' : 'bread-container'">
        <ol *ngIf='isLoggedIn && (breadcrumbs$ | async)?.length && (breadcrumbs$ | async)[0].label' class='breadcrumb'>
          <li *ngFor='let breadcrumb of breadcrumbs$ | async; let i = index' class='breadcrumb-item'>
            <a *ngIf='breadcrumb.isSelectableSegment' class='hyperlink'
               (click)='onSelectBreadcrumb(breadcrumb, i, breadcrumbs$)'>
              {{ breadcrumb.label }}
            </a>
            <a *ngIf='!breadcrumb.isSelectableSegment' class='not-selectable'>{{ breadcrumb.label }}</a>
            <a *ngIf='breadcrumb.label === null' class='not-selectable'>
              {{ breadcrumbVariable | async }}
            </a>
          </li>
        </ol>
      </div>
    </div>
    <div *ngIf='canShowWidgets()' [class]="isHome ? 'content-margin-home' : 'content-margin-not-home'">
      <div class='container'>
        <div class='row'>
          <div
            [class]="isLoggedIn ? 'col-12 col-md-9 col-lg-9 content-padding' : 'col-12 col-md-12 col-lg-12 content-padding'">
            <router-outlet></router-outlet>
          </div>
          <div *ngIf='isLoggedIn' class='col-12 col-md-3 col-lg-3 pb-4 hide-widget'>
            <app-widgets></app-widgets>
          </div>
        </div>
      </div>
    </div>
    <!-- FOOTER -->
    <div *ngIf='!isQRPage'>
      <app-footer></app-footer>
    </div>
  </div>

</div>
