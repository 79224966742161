<section *ngIf='!isLoading' class='section-container'>
  <div class='row'>
    <label>Click on the files below to access posters and brochures with contact details customised to your program.
      Freely
      use these assets for digital distribution, printing on site, or customising to your branding.</label>
  </div>

  <div *ngIf='company.bookingCode'>
    <div *ngFor='let templateType of templateTypes' class='row'>
      <div
        [ngClass]='templateType.name === walletCardTemplateType ? "col-lg-wc col-md-6 col-sm-12 no-padding-left" : "col-12 no-padding-left"'>
        <div>
          <label class='bold templateType'>{{templateType.displayName || templateType.name}}</label>
        </div>
        <div class='row full-width'>
          <div *ngFor='let template of getTemplateByTypes(templateType.name)'
               [ngClass]='templateType.name === walletCardTemplateType ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-pb col-md-4 col-sm-6"'>
            <a [routerLink]='handleViewDetailTemplate(template.id)' class='brochure-img'>
              <img src='{{template.thumbnailUrl}}' alt='{{template.name}}' />
            </a>
            <p class='templateName'>{{template.name}}</p>
          </div>
        </div>
      </div>
      <div *ngIf='templateType.name === walletCardTemplateType' class='col-sm-12 col-md-12 col-lg-al'>
        <div class='row'>
          <div class='col-lg-ag col-md-12 col-sm-12'>
            <label class='bold templateType'>ALTIUSLIFE</label>
            <div class='row'>
              <div class='col-lg-landscape col-md-12 col-sm-12'>
                <div class='col-6 no-padding-left'>
                  <a [routerLink]='marketingBrochureUrl'>
                    <img src='assets/img/marketing-brochure/tiny.png' alt='marketing-brochure' />
                  </a>
                  <p class='templateName'>AltiusLife Landscape</p>
                </div>
              </div>
              <div class='col-lg-ap col-md-12 col-sm-12'>
                <div class='no-padding-left'>
                  <a [routerLink]='marketingPosterUrl'>
                    <img src='assets/img/marketing-poster/tiny.png' alt='marketing-poster' />
                  </a>
                  <p class='templateName'>AltiusLife Posters</p>
                </div>
              </div>
            </div>
          </div>

          <div class='col-lg-ob col-md-6 col-sm-12'>
            <label class='bold templateType'>ONLINE BOOKING</label>
            <div>
              <a [routerLink]='bookingPosterUrl'>
                <img src='assets/img/booking-poster/tiny.png' alt='booking-poster' />
              </a>
              <p class='templateName no-padding-horizontal'>Online Booking Promotion</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf='!company.bookingCode'>
    <div class='col-sm-12 col-md-12 col-lg-al'>
      <div class='row'>
        <div class='col-lg-ag col-md-12 col-sm-12'>
          <label class='bold templateType'>ALTIUSLIFE</label>
          <div class='row'>
            <div class='col-lg-landscape col-md-12 col-sm-12'>
              <div class='col-6 no-padding-left'>
                <a [routerLink]='marketingBrochureUrl'>
                  <img src='assets/img/marketing-brochure/tiny.png' alt='marketing-brochure' />
                </a>
                <p class='templateName'>AltiusLife Landscape</p>
              </div>
            </div>
            <div class='col-lg-ap col-md-12 col-sm-12'>
              <div class='no-padding-left'>
                <a [routerLink]='marketingPosterUrl'>
                  <img src='assets/img/marketing-poster/tiny.png' alt='marketing-poster' />
                </a>
                <p class='templateName'>AltiusLife Posters</p>
              </div>
            </div>
          </div>
        </div>

        <div class='col-lg-ob col-md-6 col-sm-12'>
          <label class='bold templateType'>ONLINE BOOKING</label>
          <div>
            <a [routerLink]='bookingPosterUrl'>
              <img src='assets/img/booking-poster/tiny.png' alt='booking-poster' />
            </a>
            <p class='templateName no-padding-horizontal'>Online Booking Promotion</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
