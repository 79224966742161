import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Location, PopStateEvent } from '@angular/common';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/_active-environment/environment';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { FeatureService } from '../services/feature-service';
import { UserService } from '../services/user-service';
import { StripeService } from '../services/stripe-service';
import { NotificationsService } from '../services/notifications-service';
import { BadgeService } from '../services/badge-service';
import { TMHService } from '../services/track-my-health-service';
import { GroupService } from '../services/group-service';
import { IBreadcrumb } from './_models/breadcrumb';
import { UtilService } from '../services/util-service';
import { AuthService } from '../services/auth-service';
import { AccountComponent } from './account/account.component';
import { IThirdPartyPaymentConfig } from './_models/environment-config';
import { AuthRoutePath } from './_models/auth';
import { AlertComponent } from '../components/alert-modal/alert-modal.component';
import { UnSubscribeComponent } from './_shared/un-subscribe.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { get } from 'lodash';
import { DomainCheckerService } from '../services/domain-checker.service';
import { ConnectionService } from '../services/connection-service';
import { MarketingPageService } from '../services/marketing-page-service';
import { CMS_ROUTES } from './_shared/cms-routes.constant';
import { ICMSRoute } from './_models/cms-route.interface';
import { FEATURES } from './_shared/enums';
import { CategoryService } from '../services/category-service';
import { InsightsService } from '../services/insights-service';

declare var gtag: Function;
const BootstrapDisplayNone = 'd-none';

const IGNORE_PATHS = ['/', '/auth/login', '/auth/register'];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends UnSubscribeComponent implements OnInit {
  isLoggedIn = false;
  isCMS = false;
  isSettings = false;
  isQRPage = false;
  isProfileCreation = false;
  isAuthForm = false;
  breadcrumbVariable: Observable<string>;
  breadcrumb = new BehaviorSubject<IBreadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumb.asObservable();
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  cmsTitle = 'Content Mangement System';
  settingsTitle = 'Settings';
  isHome = false;
  sideMenu = BootstrapDisplayNone;
  alertModal: NgbModalRef;
  cmsRoutes: Array<ICMSRoute> = [];

  constructor(
    public userService: UserService,
    public notificationService: NotificationsService,
    public badgeService: BadgeService,
    public tmhService: TMHService,
    public groupService: GroupService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private location: Location,
    private modalService: NgbModal,
    public stripeService: StripeService,
    private authService: AuthService,
    private titleService: Title,
    private feature: FeatureService,
    private breadcrumbService: BreadcrumbService,
    private domainChecker: DomainCheckerService,
    private connectionService: ConnectionService,
    private marketingPageService: MarketingPageService,
    private categoryService: CategoryService,
    private insightsService: InsightsService,
  ) {
    super();
    this.handleWindowScroll();
    this.handleGoogleAnalyticsRouteTracking();
    // MAINTAINS SCROLL POSTIONS. WILL GO TO TOP OF PAGE IF CHILD BUT GOES BACK TO LAST SCROLL POSITION IF PARENT
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
  }

  ngOnInit() {
    this.connectionService.getCompanyConnectionByHostname(
      this.domainChecker.hostname,
    );
    this.marketingPageService.getMarketingPageData();
    this.userService.loadHomePageTheming(this.userService.envVariables);
    this.userService.envVariables = environment;

    this.checkLoggedInUser();
    this.authService.refreshTokenBeforeExpired();
    this.handleObservableUser();

    // if (!this.userService.hasProfile() && this.userService.user) {
    //   this.logOut();
    // } else {
    //   this.isLoggedIn = this.userService.isLoggedIn();
    //   this.checkRouteParams();
    //   this.handleRouteEvents();
    //   this.handleObservableToken();
    //   this.titleService.setTitle(this.userService.getApplicationTitle());
    //   this.initCMSRoutes();
    // }
  }

  private checkLoggedInUser(reload = false) {
    this.checkRouteParams();
    this.handleRouteEvents();
    this.isLoggedIn = this.userService.isLoggedIn();

    if (this.userService.isLoggedIn()) {
      this.userService.updateTimezoneAndLastlogin();
      this.initOneSignal();
      this.userService.refreshLocalStorageUser()
        .subscribe(() => {
          if (!this.userService.company.isActive) {
            this.logOut();
            return;
          }

          if (this.userService.isLoggedIn() && this.insightsService.isInsightsDomain() && !this.userService.hasInsightsAccess()) {
            this.authService.logout();
            return;
          }

          if (this.isUserProfileNotCreated()) {
            this.router.navigateByUrl('/create');
            return;
          }

          this.refreshObservables();

          const thirdPartyPaymentEntity: IThirdPartyPaymentConfig = this.userService.getThirdPartyPaymentEntityByName(this.userService.user.company.name);
          if (thirdPartyPaymentEntity) {
            this.checkThirdPaymentCurrentlySubscribed(thirdPartyPaymentEntity);
          } else if (this.userService.user.stripeCustomerId) {
            this.checkStripeStatus();
          } else {
            this.checkIfNewStripeSubscriptionRequired();
          }

          this.initCMSRoutes();

          if (reload) {
            window.location.reload();
          }
        });
    }
    this.handleObservableToken();
    this.titleService.setTitle(this.insightsService.isInsightsDomain() ? this.userService.getAltiusInsightsTitle() : this.userService.getAltiusLifeTitle());
  }

  private initOneSignal() {
    const OneSignal = window['OneSignal'] || [];
    OneSignal.push(function() {
      OneSignal.init({
        appId: environment.notifications.oneSignal.oneSignalAppId,
        safari_web_id: environment.notifications.oneSignal.oneSignalSafariWebId,
        autoRegister: environment.name != 'altiuslife-dev',
        notifyButton: {
          enable: false,
        },
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'origin',
        welcomeNotification: {
          'title': environment.title,
          'message': 'Thanks for subscribing!',
          // "url": "" /* Leave commented for the notification to not open a window on Chrome and Firefox (on Safari, it opens to your webpage) */
        },
        promptOptions: {
          /* actionMessage limited to 90 characters */
          actionMessage: 'We\'d like to show you notifications for the latest news and updates.',
          /* acceptButtonText limited to 15 characters */
          acceptButtonText: 'ALLOW',
          /* cancelButtonText limited to 15 characters */
          cancelButtonText: 'NO THANKS',
        },
      });

      if (!localStorage.getItem('oneSignalUserId')) {
        OneSignal.getUserId(function(userId) {
          if (userId !== null) {
            localStorage.setItem('oneSignalUserId', userId);
          }
        });
      }
    });
  }

  private initCMSRoutes() {
    const userRole = get(this.userService.user, 'role');
    this.cmsRoutes = CMS_ROUTES.map((item) => {
      const { groupTitle, groupId, groupHref, routes } = item;
      return {
        groupTitle,
        groupId,
        groupHref,
        routes: routes.filter(route => route.roles.includes(userRole)),
      };
    });
  }

  private checkThirdPaymentCurrentlySubscribed(paymentEntity: IThirdPartyPaymentConfig) {
    this.authService.getIsUserCurrentlySubscribed()
      .subscribe(res => {
        if (res.data && !res.data.isCurrentlySubscribed) {
          const msg = `Your subscription with ${paymentEntity.name} is not current`;
          this.utilService.showToastError('Not subscribed', msg);
          this.logOut();
        }
      });
  }

  private checkStripeStatus() {
    this.stripeService.checkStripeStatus(this.userService.user.stripeCustomerId)
      .subscribe(data => {
        if (data.success === false) {
          this.utilService.showToastError(data.err);
          this.logOut();
        }
      });
  }

  private checkIfNewStripeSubscriptionRequired() {
    this.stripeService.getIsNewStripePlanSubscriptionRequired(this.userService.user.id)
      .subscribe(res => {
        if (res.data && res.data.isNewStripePlanSubscriptionRequired) {
          const msge = 'You must subscribe to be able to continue to access the site';
          this.utilService.showToastError('Must subscribe', msge);
          this.logOut();
        }
      });
  }

  private logOut() {
    const envVariables = this.userService.envVariables;
    this.isLoggedIn = false;
    this.authService.logout();
    // window.location.href = this.getUrlForEnvironment();
    this.userService.envVariables = envVariables;
    this.sideMenu = BootstrapDisplayNone;
  }

  canShowAppNavbar(): boolean {
    return !this.isQRPage && (this.isLoggedIn || this.isAuthForm);
  }

  canShowSubscription(): boolean {
    return this.userService.isSubscribedUser();
  }

  canShowBreadcrumbs(): boolean {
    return !this.isCMS
      && !this.isSettings && !this.isProfileCreation
      && this.userService.hasProfile()
      && !this.isQRPage;
  }

  isInsightsDomain() {
    return this.insightsService.isInsightsDomain();
  }

  canShowWidgets(): boolean {
    return !this.isCMS
      && !this.isSettings && !this.isProfileCreation
      && this.userService.hasProfile()
      && !this.isQRPage;
  }

  canShowCarousel(): boolean {
    return this.isHome;
  }

  canShowTrackMyHealth(): boolean {
    return this.userService.hasCompanyFeature(this.feature.TrackMyHealthFeatureId);
  }

  canShowLeaderBoard(): boolean {
    return this.userService.hasCompanyFeature(this.feature.LeaderboardFeatureId);
  }

  canShowCompetitions(): boolean {
    return this.userService.hasCompanyFeature(this.feature.ChanceToWinFeatureId);
  }

  canShowMyRewards(): boolean {
    return this.userService.hasCompanyFeature(this.feature.MyRewardsFeatureId);
  }

  private refreshObservables() {
    if (!this.userService.hasProfile()) {
      return;
    }

    this.badgeService.refreshLatestAchievedBadge();
    this.groupService.refreshLatestGroupActivity();
    this.notificationService.refreshUnreadCount();
    this.badgeService.refreshShortLeaderboard(
      this.userService.company.id,
      this.userService.user.userProfile.id,
    );
    this.tmhService.getTrackersWithLimit().subscribe((trackers) => {
      this.tmhService.refreshActiveIndicatorsForIndicators(trackers.indicators);
      this.tmhService.displayRelevantDeviceTrackerValueMessages(trackers.indicators);
      this.displayEachAchievedBadgeAlerts(trackers.achievedBadges);
    });
  }

  private displayEachAchievedBadgeAlerts(achievedBadges) {
    if (!achievedBadges.length) {
      return;
    }

    achievedBadges.forEach(b => this.utilService.badgeAlert([b]));
  }

  handleRouteEvents() {
    this.breadcrumbVariable = this.breadcrumbService.getBreadcrumbExtraName();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isCMS = event.url.indexOf('/cms') > -1;
        this.isAuthForm = event.url.indexOf('/auth') > -1;
        this.isHome = event.url === '/home';
        this.isQRPage = event.url === '/qr';
        this.isSettings = event.url.indexOf('/settings') > -1;
        this.isProfileCreation = event.url.indexOf('/create') > -1;
        if (this.isLoggedIn) {
          forkJoin(this.feature.getAllFeatures(), this.categoryService.refreshCache(true))
            .subscribe(() => {
              const breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
              this.breadcrumb.next(breadcrumbs);
            });
        }

        this.breadcrumbs$.subscribe((title) => {
          if (title[title.length - 1]) {
            this.cmsTitle = title[title.length - 1].label;
            this.settingsTitle = title[title.length - 1].label;
          }
        });
      }
    });
  }

  handleWindowScroll() {
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (ev instanceof NavigationEnd) {
        if (ev.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  handleGoogleAnalyticsRouteTracking() {
    this.router.events.pipe(
      distinctUntilChanged((previous: any, current: any) => {
        // Subscribe to any `NavigationEnd` events where the url has changed
        if (current instanceof NavigationEnd) {
          return previous.url === current.url;
        }
        return true;
      }))
      .subscribe((x: any) => {
        gtag('config', environment.analytics.googleAnalytics.ga4MeasurementId, { page_path: x.url });
        gtag('config', environment.analytics.googleAnalytics.viewId, { page_path: x.url });
      });
  }

  checkRouteParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.verifyRedirect === 'true') {
        this.utilService.showToastSuccess('Your account is now verified! Please login');
        this.router.navigateByUrl(AuthRoutePath.login);
      }
    });
  }

  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: IBreadcrumb[] = [],
  ): IBreadcrumb[] {
    let path;
    let label;
    const params = this.breadcrumbService.initialiseParams(breadcrumbs);
    this.breadcrumbService.clearBreadcrumbExtraName();

    let nextUrl;
    if (this.breadcrumbService.isLazilyLoadableRoute(route)) {
      nextUrl = route.firstChild.routeConfig.path;
      return this.buildBreadCrumb(route.firstChild.firstChild, nextUrl, []);
    } else if (this.breadcrumbService.isRootRoute(route)) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, []);
    } else if (this.breadcrumbService.isContentItemsRoute(route)) {
      return this.breadcrumbService.getContentItemsBreadcrumbs(route);
    } else if (this.breadcrumbService.isContentItemRoute(route)) {
      return this.breadcrumbService.getContentItemBreadcrumbs(route);
    } else if (this.breadcrumbService.isPathValueParameter(route)) {
      nextUrl = url;
      route.url.subscribe((data) => {
        const segmentPath = data[0].path;
        params.push(segmentPath);

        if (data[1] && data[1].path === 'result') {
          params.push('result');
        }

        if (this.breadcrumbService.isNullLabelSegmentPath(segmentPath)) {
          label = null;
        } else if (this.utilService.isKebabCased(segmentPath)) {
          const removedId = this.utilService.removeAppendedId(segmentPath);
          label = this.utilService.toStartCase(removedId);
        } else if (route.routeConfig.data.breadcrumb === 'withId'
          && segmentPath.toString().includes('-')
        ) {
          const urlAsString = this.utilService.fromKebabCase(segmentPath);
          const indexOfId = urlAsString.lastIndexOf(' ');
          label = this.utilService.toStartCase(urlAsString.substring(0, indexOfId));
        } else {
          label = this.utilService.toStartCase(segmentPath);
        }
      });
    } else if (route.routeConfig) {
      path = route.routeConfig.path;
      if (this.breadcrumbService.isPathContainValueParameter(route)) {
        route.url.subscribe(data => {
          path = data.map(item => item.path).join('/');
        });
      }

      nextUrl = `${url}${path}/`;
      label = (route.routeConfig.data && route.routeConfig.data['breadcrumb'])
        ? route.routeConfig.data['breadcrumb']
        : '';

      const data = route.routeConfig.data;
      if (data && data.featureId === FEATURES.INFO_PACKS) {
        const companyName = this.userService.company.name;
        label = companyName ? `${companyName} Information` : label;
      }
    }

    const breadcrumb: IBreadcrumb = {
      label: label,
      url: nextUrl,
      params: params,
    };
    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    this.breadcrumbService.setIsSelectableSegment(newBreadcrumbs);

    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }

  canRedirectToBreadcrumb(breadcrumb: IBreadcrumb): boolean {
    if (breadcrumb.label === null) {
      return false;
    }

    return breadcrumb.isSelectableSegment;
  }

  isCurrentPageBreadcrumb(index: number): boolean {
    return index < 5;
  }

  onSelectBreadcrumb(breadcrumb) {
    if (!breadcrumb.params.length) {
      this.router.navigate([breadcrumb.url]);
    } else {
      const array = [];
      array.push(breadcrumb.url);
      breadcrumb.params.map((param) => {
        array.push(param);
      });
      this.router.navigate(array);
    }
  }

  openProfileModal() {
    this.modalService.open(AccountComponent).result
      .then(() => {
        this.userService.changeProfilePicObs();
      })
      .catch();
  }

  private handleObservableToken() {
    this.userService.tokenStorageObservable
      .pipe(distinctUntilChanged(), skip(1), takeUntil(this.unSubscribeOnDestroy))
      .subscribe((token) => {
        const isInIgnorePaths = IGNORE_PATHS.some((path) => path === location.pathname);
        if (token) {
          this.checkLoggedInUser(true);
          if (this.alertModal) {
            this.alertModal.dismiss(null);
            this.alertModal = null;
          }
          if (isInIgnorePaths) {
            const userProfile = get(this.userService.user, 'userProfile', null);
            if (userProfile) {
              this.router.navigateByUrl('home').then(() => {
                location.reload();
              });
            }
          } else {
            if (location.pathname !== '/create') {
              location.reload();
            }
          }
        } else {
          if (!isInIgnorePaths || this.isLoggedIn) {
            if (this.alertModal) {
              return;
            }
            this.alertModal = this.modalService.open(AlertComponent, {
              beforeDismiss: () => false,
            });
            this.alertModal.componentInstance.title = 'Attention!';
            this.alertModal.componentInstance.message =
              'Your session has expired and you will need to login again.';
            this.alertModal.componentInstance.type = 1;
            this.alertModal.componentInstance.showCloseIcon = false;
            this.alertModal.result.then(() => {
              this.alertModal = null;
              this.router.navigateByUrl('/').then(() => location.reload());
            });
          }
        }
      });
  }

  private handleObservableUser() {
    this.userService.userStorageObservable
      .pipe(distinctUntilChanged(), takeUntil(this.unSubscribeOnDestroy))
      .subscribe((user) => {
        if (!user && !!this.userService.token) {
          this.checkLoggedInUser(true);
          return;
        }

        if (location.pathname === '/create' && this.userService.hasProfile()) {
          this.router.navigateByUrl('/').then(() => location.reload());
          return;
        }
      });
  }

  canShowInsightsContent() {
    this.isLoggedIn && this.userService.hasInsightsAccess();
  }

  isUserProfileNotCreated(): boolean {
    return this.userService.isLoggedIn() && !this.userService.hasProfile();
  }
}
